import {useNavigate} from 'react-router-dom'

const AddSystem = () => {
  const navigate = useNavigate()

  const onBackHomeClicked = () => {
    navigate(`/`)
  }
  return (
    <>
      <div>To Do: Add System</div>
      <button className='btn btn-secondary mt-5' onClick={onBackHomeClicked}>
        <i className='bi bi-arrow-left'></i> Back Home
      </button>
    </>
  )
}

export default AddSystem
