import { combineReducers } from "redux";
import brandsReducer from 'redux/reducers/brandsReducer';
import systemsReducer from 'redux/reducers/systemsReducer';
import asyncReducer from "redux/reducers/asyncReducer";

const rootReducer = combineReducers({
  brands: brandsReducer,
  systems: systemsReducer,
  async: asyncReducer
})

export default rootReducer;
