import { Provider } from 'react-redux' // redux
import store from 'redux/store/store' // Redux
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from 'components/layout/navbar/Header'
import Footer from 'components/layout/footer/Footer'
import List from 'components/systems/list/List'
import ItemDetails from 'components/systems/details/ItemDetails'
import AddSystem from 'components/systems/add/AddSystem'
import './App.css'

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      {/* Basename is used due to deployment in subfolder on my web site */}
      <Provider store={store}>
        <Header />
        <div className='container'>
          <Routes>
            <Route path='/' element={<List />} />
            <Route path='systems/:systemId' element={<ItemDetails />} />
            <Route path='/add-system' element={<AddSystem />} />
            <Route path='*' element={<List />} />
          </Routes>
        </div>
        <Footer />
      </Provider>
    </Router>
  )
}

export default App
