import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { setSelectedSystem } from 'redux/actions/systemsActions'

const Item = ({ system }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onSystemClicked = () => {
    dispatch(setSelectedSystem(system))
    navigate(`/systems/${system._id}`)
  }

  return (
    <div className='col'>
      <div
        className='card system-details-card text-center'
        onClick={onSystemClicked}
      >
        <h5 className='card-title mt-1 py-1'>{system.serial_number}</h5>
      </div>
    </div>
  )
}

Item.propTypes = {
  system: PropTypes.object.isRequired
}

export default Item
