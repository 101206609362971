import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {updateSystem, deleteSystem} from 'redux/actions/systemsActions'

const ItemDetails = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {selectedSystem} = useSelector(state => state.systems)
  const {loading, error} = useSelector(state => state.async)
  const {register, handleSubmit} = useForm({mode: 'onChange'})

  const onSubmit = data => {
    dispatch(updateSystem(data, selectedSystem._id))
    setTimeout(function () {
      navigate(`/`)
    }, 500)
  }

  const onBackHomeClicked = () => {
    navigate(`/`)
  }

  const onDeleteClicked = () => {
    if (window.confirm('Are you sure? This can NOT be undone!')) {
      dispatch(deleteSystem(selectedSystem._id))
      setTimeout(function () {
        navigate(`/`)
      }, 500)
    }
  }

  return (
    <div>
      <div className='row mt-5'>
        <div className='col-sm-12 offset-md-2 col-md-8 offset-xxl-4 col-xxl-4 px-3'>
          {error && <h3>{error}</h3>}
          {selectedSystem && (
            <div className='card system-details-card p-4'>
              <div className='card-body'>
                <h4 className='card-title text-center'>
                  <i className='bi bi-clipboard2-pulse'></i>{' '}
                  {selectedSystem?.brand.name} {selectedSystem?.serial_number}
                </h4>
                <div className='mt-4'>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-check'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        id='isValidated'
                        defaultChecked={selectedSystem?.is_validated}
                        {...register('is_validated')}
                      />
                      <label className='form-check-label' htmlFor='isValidated'>
                        Validated
                      </label>
                    </div>
                    <div className='form-check mt-'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        id='isInUA'
                        defaultChecked={selectedSystem?.is_in_UA}
                        {...register('is_in_UA')}
                      />
                      <label className='form-check-label' htmlFor='isInUA'>
                        In UA
                      </label>
                    </div>
                    <div className='form-check mt-'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        id='isOnline'
                        defaultChecked={selectedSystem?.is_online}
                        {...register('is_online')}
                      />
                      <label className='form-check-label' htmlFor='isOnline'>
                        Online
                      </label>
                    </div>
                    <div className='d-flex justify-content-between mt-5'>
                      <button
                        className='btn btn-success'
                        type='submit'
                        value='Update system'
                      >
                        Update system{' '}
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-pencil-fill mb-1'
                          viewBox='0 0 16 16'
                        >
                          <path d='M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z' />
                        </svg>
                      </button>{' '}
                      <button
                        type='button'
                        className='btn btn-danger'
                        onClick={onDeleteClicked}
                      >
                        Delete system{' '}
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          fill='currentColor'
                          className='bi bi-trash mb-1'
                          viewBox='0 0 16 16'
                        >
                          <path d='M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z' />
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <button className='btn btn-secondary mt-5' onClick={onBackHomeClicked}>
        <i className='bi bi-arrow-left'></i> Back Home
      </button>
    </div>
  )
}

export default ItemDetails
