import {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {getSystemsByCurrentBrand} from 'redux/actions/systemsActions'
import Item from 'components/systems/item/Item'
import Loader from 'components/common/loader/Loader'

const List = () => {
  const dispatch = useDispatch()
  const {systems} = useSelector(state => state.systems)
  const {selectedBrand} = useSelector(state => state.brands)
  const {loading, error} = useSelector(state => state.async)

  useEffect(() => {
    dispatch(getSystemsByCurrentBrand())
  }, [selectedBrand, dispatch])

  return (
    <div>
      <div className='text-center'>{error && <h3>{error}</h3>}</div>
      <h3 className='mt-5 py-1'>
        Brand:{' '}
        <span className='text-success'>
          <em>{selectedBrand.name}</em>
        </span>
      </h3>
      {loading && systems.length === 0 && <Loader />}
      <div className='row row-cols-1 row-cols-md-3 row-cols-lg-4 g-3 mt-2 px-2'>
        {systems &&
          systems.map(system => <Item key={system._id} system={system} />)}
      </div>
    </div>
  )
}

export default List
