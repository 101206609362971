import {FETCH_BRANDS, SET_SELECTED_BRAND} from 'redux/actions/types'

const DEFAULT_SELECTED_BRAND = {_id: '62728d0556520d9eb36e1c6f', name: 'Robot'}
const initialState = {
  brands: [],
  selectedBrand: DEFAULT_SELECTED_BRAND
}

const brandsReducer = (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {
    case FETCH_BRANDS:
      return {
        ...state,
        brands: payload
      }

    case SET_SELECTED_BRAND:
      return {
        ...state,
        selectedBrand: payload
      }

    default:
      return state
  }
}

export default brandsReducer
