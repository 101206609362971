import {useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {getBrands, setSelectedBrand} from 'redux/actions/brandsActions'

const GITLAB_REPO_URL = process.env.REACT_APP_GITLAB_REPO_URL

const Header = () => {
  const {brands} = useSelector(state => state.brands)
  const dispatch = useDispatch()
  const location = useLocation()
  const {pathname} = location

  useEffect(() => {
    if (brands.length === 0 && pathname === '/') {
      dispatch(getBrands())
    }
  }, [brands, pathname, dispatch])

  const onSelectSystem = brand => {
    dispatch(setSelectedBrand(brand))
  }

  return (
    <nav className='navbar navbar-expand-lg navbar-dark bg-success'>
      <div className='container-fluid'>
        <Link to='/' className='navbar-brand'>
          <h3>{process.env.REACT_APP_BRAND}</h3>
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarNavDarkDropdown'
          aria-controls='navbarNavDarkDropdown'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarNavDarkDropdown'>
          <ul className='navbar-nav'>
            {pathname === '/' && (
              <>
                <li className='nav-item dropdown'>
                  <Link
                    to='/'
                    className='nav-link dropdown-toggle text-white'
                    id='navbarDarkDropdownMenuLink'
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    Select Brand
                  </Link>

                  <ul
                    className='dropdown-menu dropdown-menu-success'
                    aria-labelledby='navbarDarkDropdownMenuLink'
                  >
                    {brands &&
                      brands.length > 0 &&
                      brands.map(brand => (
                        <li
                          key={brand._id}
                          className='dropdown-item'
                          onClick={() => onSelectSystem({...brand})}
                        >
                          {brand.name}
                        </li>
                      ))}
                  </ul>
                </li>
                <li className='nav-item'>
                  <Link to='/add-system' className='nav-link text-white'>
                    Add System
                  </Link>
                </li>
              </>
            )}
            <li className='nav-item'>
              <a
                href={GITLAB_REPO_URL}
                target='_blank'
                rel='noreferrer'
                className='nav-link text-white external_link'
              >
                Code on GitLab
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Header
