import axios from 'axios';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from 'redux/actions/asyncActions'
import {FETCH_BRANDS, SET_SELECTED_BRAND} from 'redux/actions/types'

export const getBrands = () => async dispatch => {
  const url = `${process.env.REACT_APP_ROOT_URL_OF_API}/brands`
  try {
    dispatch(asyncActionStart())
    const res = await axios.get(url)
    
    dispatch({
      type: FETCH_BRANDS,
      payload: res.data
    })
    dispatch(asyncActionFinish())
  } catch (error) {
    if (error.response && error.response.data) {
      dispatch(asyncActionError(error.response.data.message))
    } else {
      dispatch(asyncActionError('Server error'))
    }
  }
}

export const setSelectedBrand = brand => {
  return {type: SET_SELECTED_BRAND, payload: brand}
}