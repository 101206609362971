// Async
export const ASYNC_ACTION_START = "ASYNC_ACTION_START";
export const ASYNC_ACTION_FINISH = "ASYNC_ACTION_FINISH";
export const ASYNC_ACTION_ERROR = "ASYNC_ACTION_ERROR";

// Brands
export const FETCH_BRANDS = "FETCH_BRANDS";
export const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND'

// Systems
export const FETCH_SYSTEMS = "FETCH_SYSTEMS";
export const ADD_SYSTEM = "ADD_SYSTEM";
export const UPDATE_SYSTEM = "UPDATE_SYSTEM";
export const DELETE_SYSTEM = "DELETE_SYSTEM";
export const SET_SELECTED_SYSTEM = "SET_SELECTED_SYSTEM";

