import {
  FETCH_SYSTEMS,
  ADD_SYSTEM,
  UPDATE_SYSTEM,
  DELETE_SYSTEM,
  SET_SELECTED_SYSTEM
} from 'redux/actions/types'

const initialState = {
  systems: [],
  selectedSystem: null
}

const systemsReducer = (state = initialState, action) => {
  const {systems} = state
  const {type, payload} = action

  switch (type) {
    case FETCH_SYSTEMS:
      return {
        ...state,
        systems: payload
      }
    case DELETE_SYSTEM:
      return {
        ...state,
        systems: systems.filter(system => system._id !== payload)
      }
    case ADD_SYSTEM:
      return {
        ...state,
        systems: [...systems, payload]
      }
    case UPDATE_SYSTEM:
      return {
        ...state,
        systems: systems.map(system => {
          if (system._id === payload._id) {
            return payload // update the system
          } else {
            return system // keep the system unchanged
          }
        })
      }
    case SET_SELECTED_SYSTEM:
      return {
        ...state,
        selectedSystem: payload
      }
    default:
      return state
  }
}

export default systemsReducer
