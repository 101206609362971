import axios from 'axios'
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from 'redux/actions/asyncActions'
import {
  FETCH_SYSTEMS,
  ADD_SYSTEM,
  UPDATE_SYSTEM,
  DELETE_SYSTEM,
  SET_SELECTED_SYSTEM
} from 'redux/actions/types'

export const getSystemsByCurrentBrand = () => async (dispatch, getState) => {
  const currentBrandId = getState().brands.selectedBrand._id
  const url = `${process.env.REACT_APP_ROOT_URL_OF_API}/systems/?brandId=${currentBrandId}`

  dispatch(asyncActionStart())
  try {
    const res = await axios.get(url)

    dispatch({
      type: FETCH_SYSTEMS,
      payload: res.data
    })
    dispatch(asyncActionFinish())
  } catch (error) {
    console.log(error)
    if (error.response && error.response.data) {
      dispatch(asyncActionError(error.response.data.message))
    } else {
      dispatch(asyncActionError('Server error'))
    }
  }
}

// Add system
// export const addSystem = system => async dispatch => {
//   dispatch(asyncActionStart())
//   try {
//     const url = 'https://jsonplaceholder.typicode.com/users'
//     // const url = `${process.env.REACT_APP_SYSTEMS_BASE_URL}`
//     const res = await fetch(url, {
//       method: 'POST',
//       body: JSON.stringify(system),
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     })
//     const data = await res.json()
//     dispatch({type: ADD_SYSTEM, payload: data})
//     dispatch(asyncActionFinish())
//   } catch (error) {
//     dispatch(asyncActionError(error)) // or error.message
//   }
// }

// Update system
export const updateSystem = (formData, systemId) => async dispatch => {
  dispatch(asyncActionStart())
  try {
    const url = `${process.env.REACT_APP_ROOT_URL_OF_API}/systems/${systemId}`

    const res = await axios.put(url, formData)

    dispatch({type: UPDATE_SYSTEM, payload: res.data})
    dispatch(asyncActionFinish())
  } catch (error) {
    console.log(error)
    if (error.response && error.response.data) {
      dispatch(asyncActionError(error.response.data.message))
    } else {
      dispatch(asyncActionError('Server error'))
    }
  }
}

// Delete system by id
export const deleteSystem = systemId => async dispatch => {
  const url = `${process.env.REACT_APP_ROOT_URL_OF_API}/systems/${systemId}`

  dispatch(asyncActionStart())
  try {
    await axios.delete(url)

    dispatch({
      type: DELETE_SYSTEM,
      payload: systemId
    })

    dispatch(asyncActionFinish())
  } catch (error) {
    console.log('Error: !!!!', error)
    if (error.response && error.response.data) {
      dispatch(asyncActionError(error.response.data.message))
    } else {
      dispatch(asyncActionError('Server error'))
    }
  }
}

export const setSelectedSystem = system => {
  return {type: SET_SELECTED_SYSTEM, payload: system}
}
